import { Row, Col } from "antd";
import Container from "../../common/Container";

import {
  FooterSection,
} from "./styles";


export default function Footer() {
   return (
     <>
       <FooterSection>
          <Container>
          <Row justify="space-between" align="middle">
            {/* Left Side - Copyright */}
            <div>
              © 2024 PlayLensPro. All rights reserved.
            </div>

            {/* Right Side - Contact Email */}
            <div>
              <a href="mailto:info@playlenspro.com">info@playlenspro.com</a>
            </div>
          </Row>
        </Container>
      </FooterSection>
     </>
  );
}






