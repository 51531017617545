import { BrowserRouter } from "react-router-dom";  // Import BrowserRouter for routing
import ReactDOM from "react-dom";
import React from "react";
import { HashRouter } from "react-router-dom";
import "antd/dist/antd.css";  // Ant Design styles

import Router from "./router";  // Import your routes configuration

// App component setup with BrowserRouter and basename for subpath
const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL || ''}> {/* Use PUBLIC_URL as the basename */}
      <Router /> {/* Router is where your actual routing happens */}
  </BrowserRouter>
);

// Render the App component into the root element
// ReactDOM.render(<App />, document.getElementById("root"));
ReactDOM.render( <React.StrictMode> <HashRouter> <App /> </HashRouter> </React.StrictMode>, document.getElementById('root') );
